.GetStarted-Title {
  margin-bottom: 12px;
  font-size: 36px;
}

.App-ClickStart {
  padding: 16px 48px;
  border: none;
  color: white;
  font-size: 24px;
  border-radius: 8px;
  margin-top: 16px;
  background: linear-gradient(330deg, #008cff, #8d00ff);
  background-size: 400% 400%;
  outline: none;
}

.App-ClickStart:hover {
  opacity: 0.7;
}

.App-ClickStartContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  flex-direction: column;
}

/** Loading Animation **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
  _display: none;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
  _display: none;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.GetStarted-About {
  position: absolute;
  bottom: 8px;
  left: 8px;
  text-align: left;
  color: white;
}

.GetStarted-About a {
  color: white;
}

.GetStarted-Tos {
  max-width: 350px;
  text-align: center;
  font-size: 12px;
  margin-top: 16px;
}

.GetStarted-Tos a {
  color: white;
}

#cpmStarAd {
  position: absolute;
  bottom: 8px;
  left: calc(50% - 150px);
  z-index: 103;
}

@media only screen and (max-width: 800px) {
  #cpmStarAd {
    transform: scale(0.7);
    left: 8px;
    top: 8px;
  }
}
