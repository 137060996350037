.blockSelect {
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  height: 80px;
  justify-content: space-between;
  align-content: space-between;
  position: relative;
}

.blockSelect-Button {
  position: absolute;
  top: -45px;
  left: 50px;
  z-index: 100;
  font-size: 24px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0px 8px;
}

.blockColorItem {
  width: 35px;
  height: 35px;
  border: 1px solid;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.blockColorNumber {
  text-align: center;
  color: white;
}

.DPad-BottomRow {
  display: flex;
  flex-direction: row;
}

.DPad {
  position: absolute;
  bottom: 0px;
  left: 8px;
  font-size: 24px;
}
