.App {
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.bottomUiContainer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
  padding: 8px;
}

.FlyControls {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  padding: 8px;
}

.FlyControls-Button {
  color: white;
  background-color: #00000095;
  border-radius: 4px;
  padding: 8px 14px;
  margin-top: 4px;
}

.FlyControls-Button:hover {
  background-color: #000000;
}

.App-HelpBanner {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #00000095;
  color: white;
  z-index: 100;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.HelpBanner-Controls {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
}

.HelpBanner-Title {
  font-size: 24px;
}

.HelpBanner-Discord {
  margin-top: 14px;
  background-color: #191c6d;
  color: white;
  border-radius: 8px;
  padding: 8px 14px;
}

.HelpBanner-Discord:hover {
  background-color: #333567;
}

.ErrorText {
  position: absolute;
  top: calc(50% + 15px);
  left: calc(50% - 50px);
  z-index: 100;
  width: 100px;
  color: white;
  background-color: #00000069;
  padding: 4px;
  border-radius: 2px;
}

.LocationShare {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  text-decoration: underline;
  z-index: 100;
  background-color: #0000009a;
  padding: 4px;
  border-radius: 2px;
}

.Promotion {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: white;
  z-index: 102;
  background-color: #0000009a;
  padding: 4px;
  border-radius: 2px;
}
