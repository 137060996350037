body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.bottomUiContainer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
  padding: 8px;
}

.FlyControls {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  padding: 8px;
}

.FlyControls-Button {
  color: white;
  background-color: #00000095;
  border-radius: 4px;
  padding: 8px 14px;
  margin-top: 4px;
}

.FlyControls-Button:hover {
  background-color: #000000;
}

.App-HelpBanner {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #00000095;
  color: white;
  z-index: 100;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.HelpBanner-Controls {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
}

.HelpBanner-Title {
  font-size: 24px;
}

.HelpBanner-Discord {
  margin-top: 14px;
  background-color: #191c6d;
  color: white;
  border-radius: 8px;
  padding: 8px 14px;
}

.HelpBanner-Discord:hover {
  background-color: #333567;
}

.ErrorText {
  position: absolute;
  top: calc(50% + 15px);
  left: calc(50% - 50px);
  z-index: 100;
  width: 100px;
  color: white;
  background-color: #00000069;
  padding: 4px;
  border-radius: 2px;
}

.LocationShare {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  text-decoration: underline;
  z-index: 100;
  background-color: #0000009a;
  padding: 4px;
  border-radius: 2px;
}

.Promotion {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: white;
  z-index: 102;
  background-color: #0000009a;
  padding: 4px;
  border-radius: 2px;
}

.blockSelect {
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  height: 80px;
  justify-content: space-between;
  align-content: space-between;
  position: relative;
}

.blockSelect-Button {
  position: absolute;
  top: -45px;
  left: 50px;
  z-index: 100;
  font-size: 24px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0px 8px;
}

.blockColorItem {
  width: 35px;
  height: 35px;
  border: 1px solid;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.blockColorNumber {
  text-align: center;
  color: white;
}

.DPad-BottomRow {
  display: flex;
  flex-direction: row;
}

.DPad {
  position: absolute;
  bottom: 0px;
  left: 8px;
  font-size: 24px;
}

.reticle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: white;
  z-index: 100;
  border-radius: 5px;
  opacity: 0.9;
}
.ColorGrid-Item {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  margin: 2px;
  border: 1px solid black;
}

.ColorGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.BlockSelect-Container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Inventory-ItemDropZone {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
}
.GetStarted-Title {
  margin-bottom: 12px;
  font-size: 36px;
}

.App-ClickStart {
  padding: 16px 48px;
  border: none;
  color: white;
  font-size: 24px;
  border-radius: 8px;
  margin-top: 16px;
  background: linear-gradient(330deg, #008cff, #8d00ff);
  background-size: 400% 400%;
  outline: none;
}

.App-ClickStart:hover {
  opacity: 0.7;
}

.App-ClickStartContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  flex-direction: column;
}

/** Loading Animation **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
  _display: none;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
  _display: none;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0);
  }
}

.GetStarted-About {
  position: absolute;
  bottom: 8px;
  left: 8px;
  text-align: left;
  color: white;
}

.GetStarted-About a {
  color: white;
}

.GetStarted-Tos {
  max-width: 350px;
  text-align: center;
  font-size: 12px;
  margin-top: 16px;
}

.GetStarted-Tos a {
  color: white;
}

#cpmStarAd {
  position: absolute;
  bottom: 8px;
  left: calc(50% - 150px);
  z-index: 103;
}

@media only screen and (max-width: 800px) {
  #cpmStarAd {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    left: 8px;
    top: 8px;
  }
}

