.ColorGrid-Item {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  margin: 2px;
  border: 1px solid black;
}

.ColorGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.BlockSelect-Container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Inventory-ItemDropZone {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
}