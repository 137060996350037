.reticle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: white;
  z-index: 100;
  border-radius: 5px;
  opacity: 0.9;
}